/* FAQ.css */
.faq-container {
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 1600px;

}   

.faq-item {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    padding: 10px;
    background-color: #fff;
    border-radius: 8px; 
}

.faq-question {
    cursor: pointer;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px; 
    font-size: 18px;

}

.faq-answer {
    padding: 10px 0;
    text-align: left; 
    font-size: 16px; 
    transition: all 0.8s ease;
}

.arrow {
    transition: transform 0.3s ease;
    font-size: 24px;
}

.arrow.down {
    transform: rotate(0);
}

.arrow.up {
    transform: rotate(180deg);
}

@media (max-width: 1500px) {
    .faq-container {
        padding: 10px;
        margin: 0;
        min-width: 100%;
    }
    .faq-question {
        font-size: 14px;
    }
    .faq-answer {
        font-size: 13px;
    }
    .arrow {
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .faq-container {
        gap: 15px;
        margin: 0;
        min-width: 100%;
         
    }
    
    .faq-question {
        font-size: 12px;
    }
    .faq-answer {
        font-size: 12px;
    }
    .arrow {
        font-size: 18px;
    }

}
@media (max-width:600px) {
    .faq-container{
        min-width: 100%;
    }
}