
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 1600px;
  
}


.header > .navbar > .nav-content{
  display: flex;
  list-style-type: none;
  gap: 40px;
  align-items: center;
  position: relative;
  
  
  
}
.header > .navbar > .nav-content a{
  text-decoration: none;
  color: #333;
  opacity: 0.6;
  transition: all 0.3s;
  
}
.header > .navbar > .nav-content a:hover{
  opacity: 1;
}


.lang_login{
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}


.lang {
  padding: 10px;
}
.lang a{
  opacity: 0.6;
  color: #333;  
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow:0px 2px 8px rgba(0,0,0,0.16);
  text-decoration: none;
  padding: 10px 20px 10px 20px;
  transition: all 0.3s;
}
.lang a:hover{
  opacity: 1;
}


.signup{
  border-radius: 12px;
  padding: 10px 20px 10px 20px;
  box-shadow:0px 2px 8px rgba(0,0,0,0.16); 
  display: flex;
  gap: 10px;

  
}
.signup li{
  list-style-type: none;

}
.signup a{
  text-decoration: none;
  color: #333;
}
/* Dropdown */

.navbar .dropdown,
.lang .dropdown,
.signup .dropdown{
  cursor: pointer;
  z-index: 11111;
}

.navbar .dropdown-menu,
.signup .dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
  z-index: 22222;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: max-content;
  top: 54px;
}


.navbar .dropdown:hover .dropdown-menu,
.signup .dropdown:hover .dropdown-menu{
  display: block;
}

.navbar .dropdown-menu li,
.signup .dropdown-menu li
 {
  padding: 12px 16px;
}

.navbar .dropdown-menu li a,
.signup .dropdown-menu li a {
  color: #030303;
}


/* Burger menu styles */
.burger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.burger-menu span {
  width: 25px;
  height: 3px;
  background-color: #030303;
  margin: 4px 0;
  transition: 0.4s;
}

/* Responsive styles */
@media screen and (max-width: 1500px) {
  .header{
    justify-content: space-evenly;
    min-width: 1000px;
    align-items: center;
  }
  .burger-menu {
      display: flex;
  }
  .navbar {
      display: none;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 11%;
      min-width: 1005px;
      z-index: 1111111;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
      
  }
  .navbar ul {
      flex-direction: column;
      gap: 20px;
      padding: 20px;
  }
  .navbar ul li {
      opacity: 1;
  }
  .navbar ul a{
      opacity: 1;
      transition: all 1s;
  }
  .navbar ul a:hover{
      opacity: 0.5;

  }
  .navbar.active {
      display: flex;
  }
  .navbar .dropdown {
      cursor: pointer;
      z-index: 11111;
      width: max-content;
  }
  .signup .dropdown-menu{
    top: 55px;
    right: 20%;
    text-align: center;
  }

  
}
.logout-button{
  border: none;
  cursor: pointer;
  font-size: 20px;

}
.profile-button{
  color: #fff;
  border-radius: 12px;

}
@media screen and (max-width: 768px ) {
  .header{
    min-width: 700px;
    justify-content: space-between;
    
  }
  
  .burger-menu {
      display: flex;
  }
  .navbar {
      display: none;
      flex-direction: column;
      align-items: center;
      position: absolute;
      z-index: 1111111;
      min-width: 700px;
      top:10%;
      
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
      
  }
  
  .navbar ul {
      flex-direction: column;
      gap: 20px;
      padding: 20px;
  }
  .navbar ul li {
      opacity: 1;
  }
  .navbar ul a{
      opacity: 1;
      transition: all 1s;
  }
  .navbar ul a:hover{
      opacity: 0.5;

  }
  .navbar.active {
      display: flex;
  }
  .navbar .dropdown {
      cursor: pointer;
      z-index: 11111;
      width: max-content;
  }
  .signup .dropdown-menu{
    top: 55px;
    right: 3%;
    text-align: center;

  }
 
  
  
}

@media screen and (max-width: 600px ) {
  /* .logo{
    position: relative;
    top: 10px;


  } */
 
  .header{
    min-width: 380px;
    align-items: center;
    justify-content: space-between;
    
  }
  .lang a,.signup{
    padding: 10px 30px;
  }
  .logo > a >img{
      width: 120px;
      top: 0px;
      left: 20px;
      position: relative;
  }
  .navbar {
      display: none;
      flex-direction: column;
      position: absolute;
      align-items: center;
      z-index: 1111111;
      min-width: 400px;
      top: 6%;
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
      
      
  }
  .navbar ul {
      flex-direction: column;
      gap: 20px;
      padding: 10px;
      align-items: center;
      position: relative;
      right: 10px;

  }
  .navbar ul li {
      opacity: 1;
  }
  .navbar ul a{
      opacity: 1;
      transition: all 1s;
  }
  .navbar ul a:hover{
      opacity: 0.5;

  }
  .navbar.active {
      display: flex;
  }
  .navbar .dropdown {
      cursor: pointer;
      z-index: 11111;
      width: max-content;
  }
  .signup .dropdown-menu{
    top: 30px;
    right: 10px;
    text-align: center;
  }
  .signup{
    padding: 8px;
  }
  .profile-button{
    margin: 0;
    font-size: 20px;
  }
  .user-email{
    display: none;
  }

}
