.blogs {
    display: flex;
    gap: 30px;
    margin-top: 40px;
    box-sizing: border-box;
    text-align: center;

    height: 110vh;
    flex-direction: column;

   
}

.blog-anons h2 {
    font-size: 24px;
    letter-spacing: 1px;
    font-weight: 600;
    text-align: center; 
}
.blogs-contents{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}
.blogs-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px;
    text-align: center;
    position: relative;
    top: 80px;
    width: 100%;
    max-width: 300px; 
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    transition: all 0.8s;
    border-radius: 12px; 
    background-color: #fff;
    height: 300px;
}

.blogs-content a {
    text-decoration: none;
    color: #333;
    font-size: 16px; /* Font size adjustment */
    font-weight: 500; /* Adjusted font weight for readability */
}

.blogs-content img {
    border-radius: 12px;
    max-width: 100%; /* Ensure image fits within container */
    height: auto; /* Maintain aspect ratio */
}

.blogs-content:hover {
    opacity: 0.9; /* Slightly reduced hover effect for subtlety */
}
@media (max-width:1669px) {
    .blogs{

        height: 180vh;

    }
    
}

@media (max-width:925px) {
    .blogs{

        height: 230vh;

    }
    
}
@media (max-width:625px) {
    .blogs{

        height: 440vh;

    }
    
}