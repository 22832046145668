.services-page {
    display: flex;
    flex-direction: column;
    min-width: 1600px;
    padding: 20px;
   
}

.left-filter {
    position: relative;
    height: 250px;
    margin-top: 10px;

}
.left-image img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    filter: blur(0.8px);
}

.left-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    flex-direction: column;
    min-width: 100%;

}

.left-search h2 {
    background-color: transparent;
    color: #fff;
    font-size: 2em;
    text-align: center;
    font-weight: 600;
    line-height: 40px;

}

.left-search form {
    display: flex;
    gap: 15px;
    font-size: 14px;
    background-color: transparent;
    margin-top: 20px;

}
.left-search select, .left-search input{
    width: 250px;
    padding: 15px;
    outline: none;
    border: 1px solid #ccc;
    text-align: center;
    font-weight: 600;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
    cursor: pointer;

}

.left-search button{
    width: 250px;
    border: none;
    background-color: #00AA6C;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    transition: all 0.8s ease;
}
.left-search button:hover{
    opacity: 0.9;
}
.service-img img {
    width: 100px;
}
.right-services{
    min-width: 1200px;

}
.service-img{
    max-width: 400px;
    height: 450px;
    margin: 0;
}

.service-option{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    align-items: center;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
    margin-top: 10px;
    gap: 50px;
    cursor: pointer;
    transition: all 0.8s ease;


}
.service-option:hover{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

}
.service-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.service-detail{
    text-align: center;
}
.service-detail p{
    opacity: 0.5;
    margin-top: 20px;
}
.service-detail button{
    padding: 10px 90px 10px 90px;
    border-radius: 12px;
    border: none;
    background-color: #FDC735;
    color: #333;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.8s ease;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);

}
.service-detail button:hover{
    opacity: 0.8;
}
.service-info{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 450px;
    cursor: pointer;
    padding: 10px;
}
.service-info:hover{
    text-decoration: underline;
}
.pagination {
    display: flex;
    justify-content: center;
    gap: 50px;
    align-items: center;
    margin-top: 20px;
}
.pagination button {
    background-color: #005f9b;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 25px;
    transition: all 0.8s ease;
    
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
    opacity: 0.8;
}

.pagination span {
    font-size: 18px;
    font-weight: bold;
}

/* Responsive Design */

@media screen and (max-width: 1500px) {
    .services-page,.right-services{
        min-width: 100%;
    }
    .left-search{
        /* background-color: #005f9b; */
        width: 100%;
        font-size: 14px;
    }
    .service-option{
        display: flex;
        flex-direction: column;
        padding: 10px;
    }
    .service-img{
        max-width: 100%;
    }
    
    .service-info{
        align-items: center;
        justify-content: center;
        text-align: center;
        /* width: 300px; */
    }
    .left-search form>select,.left-search form > input, .left-search form > button {
        width:100%;
        
    }
}
@media screen and (max-width: 768px) {
    .service-info{
        width:300px;
    }

    .left-image, .left-filter h2{
        display: none;
    }
    .left-search form,.left-search{
        flex-direction: column;
        
    
    }
    .left-search button{
        padding-top: 10px;
        padding-bottom: 10px;
    }
   
}
@media screen and (max-width: 600px) {}