.organic{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    width: 1600px;
    position: relative;


}

.organic-wrapper {
    display: flex;
    gap: 20px;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scrollbar-width: none;
    white-space: nowrap;
    justify-content: flex-start;

}

.organic-wrapper::-webkit-scrollbar {
    display: none;
}

.organic-heads {
    margin-top: 10px;
}

.scroll-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 10px;
    position: absolute;
    background-color: transparent;
    top: 250px;
    width: 100%;
    z-index: 10;

}

.scroll-buttons button {
    padding: 5px 10px;
    cursor: pointer;
    background-color: #fff;
    color: #333;
    border-radius: 50%;
    font-size: 1.2em;
    border: none;
}
.scroll-buttons i{
    background-color: transparent;
}

.organic-heads h2 {
    font-size: 24px;
    line-height: 40px;
    font-weight: 600;

}

.organic-heads p {
    font-size: 16px;
    line-height: 25px;
    opacity: 0.7;
}

.organic-item {
    padding: 10px;
    transition: 0.3s;
}

.organic-img img {
    width: 500px;
    height: 350px;
    object-fit: cover;
    border-radius: 12px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    cursor: pointer;
}

.organic-img:hover {
    opacity: 0.9;
}

.organic-content p {
    font-weight: 700;
    font-size: 18px;
}

.organic-content pre {
    font-size: 14px;
    font-weight: 500;
}
@media screen and (max-width: 1500px) {
    .organic{
        width:800px;
        margin-top: 40px;
    }
}
@media screen and (max-width: 768px ) {
    
    .organic{
        width: 500px;
    }
    .organic-heads h2{
        line-height: 50px;
    }

    .organic-heads p{
        line-height: 30px;
    }
    .organic-img{
        width: 350px;
    }
    .organic-img img{
        width: 100%;
        height: 550px;
    }
    .scroll-buttons{
        display: none;
    }

}
@media screen and (max-width: 650px ) {
    .organic{
        width: 380px;
    }
    .organic-heads{
        text-align: center;
        background-color: transparent;
    }

}