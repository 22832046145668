.container{
    display: flex;
    justify-content: center;
    box-shadow:0px 2px 8px rgba(0,0,0,0.16);
}
.footer {
    min-width: 1600px;
    display: flex;
    justify-content: space-between;
    padding: 10px;

}

.footer h2 {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    padding-bottom: 10px;
}

.footer ul {
    list-style-type: none;
    padding: 0;
}


.footer a {
    text-decoration: none;
    color: #333;
    opacity: 1;
    transition: all 0.5s;
}

.footer a:hover {
    opacity: 0.5;
}
.footer-content{
    display: flex;
    justify-content: center;
    min-width: 800px;
    gap: 40px;
}
.footer-services, .footer-news, .footer-support, .footer-links {
    display: flex;
    flex-direction: column;
    gap: 10px; 
    text-align: center;
}

.footer li {
    text-align: center;
    padding: 5px;
}

.footer-links ul {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.footer-links li {
    margin-top: 30px;
}

.footer-links i {
    font-size: 30px;
}
/* Responsive */


@media screen and (max-width: 1500px) {
    .footer{
        justify-content: center;
        min-width: 1000px;
        flex-direction: column;

        align-items: center;

      }
    .footer-content{
        flex-direction: column;

        min-width: 100%;
    }
    
}
@media screen and (max-width: 768px ) {
    .footer{
        min-width: 700px;
        
      }
}
@media screen and (max-width: 600px ) {
    .footer{
        min-width:300px;   
      }
}

