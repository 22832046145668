.login-content{
    width: 100%;
    height: 80vh;
}
.login-page {

    width: 90%; /* Changed to a percentage for responsiveness */
    max-width: 1000px; /* Maximum width for large screens */
    height: auto; /* Auto height for flexibility */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    padding: 20px; /* Added padding for better spacing */
    box-sizing: border-box; /* Ensure padding is included in width/height */
}

.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%; /* Full width for the form */
    max-width: 600px; /* Maximum width for large screens */
    height: auto; /* Auto height for flexibility */
    gap: 20px;
    padding: 20px; /* Added padding for better spacing */
    box-sizing: border-box; /* Ensure padding is included in width/height */
}

.login-form h2 {
    position: relative;
    top: 0; /* Adjusted position */
    margin-bottom: 20px; /* Added margin for spacing */
    font-size: 24px; /* Slightly reduced font size */
    letter-spacing: 1px; /* Slightly reduced letter-spacing */
    font-weight: 500;
    text-align: center; /* Centered heading */
}

.login-form label {
    text-align: left;
    padding: 7px;
    font-size: 14px; /* Slightly reduced font size */
}

.login-form input {
    padding: 15px; /* Slightly reduced padding */
    border-radius: 12px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    border: none;
    transition: all 0.7s;
    font-size: 14px; /* Slightly reduced font size */
}

.login-form input:focus {
    border: 1px solid #2daa2d;
    box-shadow: 0px 2px 12px #2daa2d;
    outline: none;
}

.login-form button {
    border-radius: 12px;
    padding: 10px;
    cursor: pointer;
    border: none;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    background-color: #2daa2d;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    opacity: 1;
    transition: all 0.4s;
    letter-spacing: 2px;
}

.login-form button:hover {
    opacity: 0.8;
}

.login-form a {
    color: #333;
    font-weight: 300;
    text-align: left;
    text-decoration: none;
    font-size: 14px; /* Slightly reduced font size */
}

.back-sign a {
    color: #333;
    font-weight: 300;
    text-align: center;
    text-decoration: none;
}

.back-sign a:hover, 
.login-form a:hover {
    opacity: 0.8;
    text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
    .login-page {
        width: 95%; /* Increased width for smaller screens */
        padding: 10px; /* Reduced padding */
    }

    .login-form {
        width: 100%; /* Full width for form */
        padding: 10px; /* Reduced padding */
    }

    .login-form h2 {
        font-size: 22px; /* Further reduced font size */
    }

    .login-form input {
        padding: 12px; /* Further reduced padding */
        font-size: 13px; /* Further reduced font size */
    }

    .login-form button {
        padding: 10px;
        font-size: 13px; /* Further reduced font size */
    }

    .login-form a, 
    .back-sign a {
        font-size: 13px; /* Further reduced font size */
    }
}

@media (max-width: 480px) {
    .login-page {
        width: 100%; /* Full width for very small screens */
        padding: 5px; /* Further reduced padding */
    }

    .login-form h2 {
        font-size: 20px; /* Further reduced font size */
    }

    .login-form input {
        padding: 10px; /* Further reduced padding */
        font-size: 12px; /* Further reduced font size */
    }

    .login-form button {
        padding: 8px;
        font-size: 12px; /* Further reduced font size */
    }

    .login-form a, 
    .back-sign a {
        font-size: 12px; /* Further reduced font size */
    }
}
