/* Container styling */
.terms-contents {
    max-width: 1500px; /* Limit the width for better readability */
    margin: 50px auto; /* Center the content and add some margin at the top */
    padding: 20px; /* Add padding inside the container */
    background-color: #f9f9f9; /* Light background for contrast */
    border-radius: 8px; /* Slightly rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    font-family: Arial, sans-serif; /* Simple, readable font */
    line-height: 1.6; /* Improve readability */
    color: #333 !important; /* Dark text color for better contrast */
}

/* Heading styling */
.terms-contents h2 {
    font-size: 2rem; /* Larger font size for the heading */
    margin-bottom: 20px; /* Space between heading and content */
    color: #1a73e8; /* Accent color for the heading */
    text-align: center; /* Center the heading */
}

/* Paragraph styling */
.terms-contents p {
    font-size: 1rem; /* Standard paragraph font size */
    margin-bottom: 15px; /* Space between paragraphs */
    text-align: justify; /* Justify the text for a cleaner look */
}

/* List styling (if needed) */
.terms-contents ul, .terms-contents ol {
    padding-left: 20px; /* Indent list items */
    margin-bottom: 15px; /* Space between list and next element */
}

/* Link styling */
.terms-contents a {
    color: #1a73e8; /* Link color */
    text-decoration: none; /* Remove underline */
}

.terms-contents a:hover {
    text-decoration: underline; /* Underline on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .terms-contents {
        padding: 15px; /* Reduce padding on smaller screens */
        margin: 20px; /* Adjust margin */
    }

    .terms-contents h2 {
        font-size: 1.5rem; /* Smaller heading on mobile */
    }

    .terms-contents p {
        font-size: 0.9rem; /* Slightly smaller text for readability on mobile */
    }
}
