*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
.container{
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #f0f0f0;

  }