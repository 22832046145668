/* Contact.css */

  .contact {
    min-width: 100%;
    height: auto;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    gap: 20px;
    width: 100%;
}

.contact-info, .contact-message {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 500px;
}

.contact-info input {
    border: none;
    padding: 15px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
    color: #333;
    font-weight: 500;
    border-radius: 12px;
    font-size: 16px;
    transition: all 0.3s ease;
}
.contact-form > .contact-info input:focus,
.contact-form > .contact-message textarea:focus
 {
    border: 1px solid #2daa2d;
    box-shadow: 0px 2px 12px #2daa2d;
    outline: none;
  }

.contact-form > .contact-message textarea {
    border: none;
    padding: 15px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
    color: #333;
    font-weight: 500;
    border-radius: 12px;
    font-size: 16px;
    width: 100%;
    height: 220px; 
    transition: all 0.3s ease;
}

.contact-form button {
    cursor: pointer;
    height: 40px;
    border: none;
    border-radius: 12px;
    background-color: #2DAA5A;
    color: #fff;
    transition: all 0.3s ease;
    opacity: 1;
    width: 100%;
    max-width: 500px;
    margin-top: 20px;
}

.contact-form button:hover {
    opacity: 0.8;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .contact h2 {
        font-size: 20px;
        line-height: 28px;
    }
}
@media (max-width: 600px) {
    .contact h2 {
        font-size: 18px;
    }
    .contact-info input {
        padding: 12px;
        font-size: 14px;
    }
   

}
