/* Header.css */
.row {
    min-width: 1600px;
    min-height: 650px;
}

.blog-banner {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #F2EDF9;
}

.blog-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    gap: 100px;
    width: 100%;
    background-color: #F2EDF9;
}

.blog-content.active {
    opacity: 1;
}

.blog-poster {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    z-index: 2;
    background-color: transparent;
    width: 500px;
}

.blog-title {
    font-size: 2.5rem;
    margin: 0;
    color: #333;
    padding: 20px;
    background-color: transparent;
}

.blog-text {
    font-size: 1rem;
    line-height: 28px;
    margin-top: 10px;
    color: #555;
    padding: 20px;
    background-color: transparent;
    position: relative;
    text-align: center;
}

.blog-text a {
    text-decoration: none;
    color: #fff;
    background-color: green;
    opacity: 0.6;
    padding: 5px 70px;
    border-radius: 24px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
    transition: opacity 0.5s;
    position: absolute;
    top: 95%;
    left: 32%;
}

.blog-text a:hover {
    opacity: 1;
}

.blog-image {
    max-width: 700px;
    min-width: 380px;
    object-fit: cover;
    border-radius: 12px;
}

/* Responsive Design */
@media screen and (max-width: 1500px) {
    .row {
        min-width: 100%;
    }
    .blog-poster {
        width: 400px;
    }
    .blog-title {
        font-size: 2rem;
    }
    .blog-content {
        gap: 50px;
    }
}

@media screen and (max-width: 768px) {
    .row {
        min-width: 100%;
    }
    .blog-content {
        flex-direction: column;
        gap: 20px;
    }
    .blog-poster {
        width: 100%;
        padding: 20px;
    }
    .blog-title {
        font-size: 1.8rem;
    }
    .blog-text a {
        padding: 5px 50px;
        top: 90%;
        left: 50%;
        transform: translateX(-50%);
    }
    .blog-image {
        max-width: 500px;
        min-width: 280px;
    }
}

@media screen and (max-width: 600px) {
    .row {
        min-width: 100%;
    }
    
    .blog-poster {
        width: 100%;
        padding: 10px;
    }
    .blog-title {
        font-size: 1.5rem;
    }
    .blog-text {
        font-size: 0.9rem;
    }
    .blog-text a {
        padding: 5px 40px;
        top: 85%;
        left: 50%;
        transform: translateX(-50%);
    }
    .blog-image {
        max-width: 100%;
        min-width: 200px;
    }
}
