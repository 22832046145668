.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 1600px;
}
.main-search{
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: relative;
  

  
}
.main-search h2 {
  text-align: center;
  padding-bottom: 20px;
  font-size: 24px;

}

.main-search ul {
  display: flex;
  list-style-type: none;
  gap: 40px;
  overflow-x: auto;
  justify-content: center;
  max-width: 900px;
  width: 100%;
  height: 50px;
  align-items: center;
  white-space: nowrap; /* Prevent items from wrapping */
}

.main-search ul li i {
  font-size: 1em;
  color: #333;
}

.main-search ul li button {
  font-size:1em;
  color: #333;
  position: relative;
  overflow: hidden;
  display: inline-block;
  padding-bottom: 5px;
  color: #333;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  font-weight: 600;
  transition: all 0.7s ease;
  

}

.main-search ul li button::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 100%;
  width: 100%;
  height: 2px;
  background: #000;
  transition: left 0.35s ease-in-out;
}

.main-search ul li button:hover::before {
  left: 0;
}
.main-search ul li button:hover{
  opacity: 0.5;
}
.search{
  position: relative;
  width: 100%;
  top: -20px;
}
.search input {
  text-align: center;  
  padding: 18px 15px 18px 15px;
  width: 100%;
  border-radius:6em;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  transition: all 0.8s;
}

.search input:focus {
  border: 1px solid #2daa2d;
  box-shadow: 0px 2px 12px #2daa2d;
  outline: none;
}

.search i {
  font-size: 1em;
  transform: translate(10px,40px);
  background-color: transparent;
  
}

.search button {
  position: absolute;
  left: 90%;
  top: 40%;
  background-color: rgb(0, 161, 0);
  color: white;
  border: none;
  padding: 10px 13px;
  border-radius: 2em;
  transition: all 0.8s;
  cursor: pointer;
  font-weight: 700;
  font-size:10px;
}

.search button:hover {
  opacity: 0.5;
}

.news-banner{
  position: relative;
  min-width: 1600px;
  height:500px;
  background-color: transparent;


}
.news-poster {
  /* background-color: green; */
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  opacity: 0;
  transition: opacity 1s ease-in-out; /* Fade transition */
  padding-left: 5px;


}
.news-poster img{
  width: 800px;
  height: 450px;
  object-fit: cover;
  border-radius: 12px;
}
.news-poster.active {
  opacity: 1; /* Make the active slide visible */
}
.news-poster h2{
  position: absolute;
  top: 80%;
  left: 5%;
  background-color: transparent;
  color: #fff;
  font-weight: 700;
  font-size: 2em;
  letter-spacing: 2px;

}
.news-poster a{
  background-color: transparent;
  position: absolute;
  text-decoration: none;
  color: #333;
  padding: 5px;
  border: 1px solid;
  background-color: #fff;
  border-radius: 12px;
  top: 90%;
  left: 5%;
}


/* Responsive */

@media screen and (max-width: 1500px) {
  .main,.news-banner{
    min-width: 1000px;
  }

  .main-search ul {
   width: 950px;
  }
  .search{
    display: flex;
    flex-direction: column;
    position: static;
    gap: 10px;
    padding: 10px;
    width: 80%;
    border: 1px solid #ccc;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  }

  
  .search input{
    border-bottom:none;
    border-radius: 0;
    text-align: center;
  }
  .search button{
    position: static;
    text-align: center;
    padding: 15px;
    
  }
  .news-banner{
    top: 20px;
  }

  .news-poster a{
    border: 1px solid;
  }
}
@media screen and (max-width: 768px ) {
  .main,.news-banner{
    min-width: 700px;
  }
  .news-poster img{
    width: 600px;
  }
  .main-search ul {
    justify-content: flex-start;
    width: 450px;

   }
   .search{
    width: 600px !important;
  }

  
}
@media screen and (max-width: 600px ) {
  .main,.news-banner{
    min-width: 380px;
  }
  .news-poster img{
    width: 370px;
  }
  .news-banner h2{
    top: 50%;
  }
  .news-banner a{
    top: 70%;
  }
  .main-search ul {
    width: 380px;
   }
   .search{
    width: 380px !important;
   }

}